//
// forms.scss
//

// Form-control light

// .form-control-light {
// 	background-color: $gray-100;
// 	border-color: $gray-100;
// }

// // Form elements (Color and Range)

// input.form-control[type='color'],
// input.form-control[type='range'] {
// 	min-height: 39px;
// }

// // Input

// input {
// 	&:focus {
// 		outline: none;
// 	}
// }

// input.form-control {
// 	&:disabled {
// 		background-color: $gray-200;
// 	}

// 	&::placeholder {
// 		color: $gray-500;
// 	}
// }

.form-control:disabled,
.form-control[readonly] {
	background-color: $gray-200;
	opacity: $btn-disabled-opacity;
}
