@import '../../../../styles/bootstrap-variables.scss';

.ecommerce-footer {
	margin-top: 1px;
	background-color: theme-color('dark');

	ul > li {
		color: darken(theme-color('light'), 10%);
		margin-bottom: 0.4rem;
	}
	p {
		color: darken(theme-color('light'), 10%);
	}

	ul > li:not(.list-inline-item) > a {
		color: darken(theme-color('light'), 15%);

		&:hover {
			color: theme-color('light');
		}
	}

	li.list-inline-item {
		> a {
			display: inline-block;
			border: 2px solid theme-color('primary');
			border-radius: 50%;
			width: 2rem;
			height: 2rem;
			text-align: center;
			color: theme-color('primary');
			font-size: 1rem;
			line-height: 1.8rem;
			transition: all 0.2s;

			&:hover {
				border: 2px solid theme-color('primary');
				background-color: theme-color('primary');
				color: theme-color('dark');
			}
		}
	}
}
