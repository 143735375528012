/*
Amakha Paris
Version: 1.0.0
Website: https://amakhaparis.com.br/
*/

@import 'bootstrap';
$font-family-secondary: 'Lato', sans-serif;

// Reduz fonte base para 14px
html {
	font-size: 81.25%;
}

:root {
	label {
		font-size: 0.9em;
		font-weight: 500;
	}

	small,
	.small {
		font-weight: 400;
	}

	.no-wrap {
		white-space: nowrap;
	}

	h1,
	h2,
	h3 {
		font-weight: #{$font-weight-light};
		font-family: #{$font-family-secondary};
	}
	h4,
	h5 {
		font-weight: #{$font-weight-normal};
		font-family: #{$font-family-secondary};
	}

	//
	// Scrollbar
	//
	* {
		::-webkit-scrollbar {
			width: 0.5rem;
			height: 0.5rem;
		}

		::-webkit-scrollbar-thumb {
			background: #dee2e5;
			border-radius: 5px;
			cursor: pointer;
			opacity: 0.4;
			transition: 0.2s;
		}

		&:hover::-webkit-scrollbar-thumb {
			background: #d1d6da;
		}

		::-webkit-scrollbar-track {
			background: transparent;
		}
	}

	//
	// Cores base
	//
	--gray-10: #{$gray-100};
	--gray-20: #{$gray-200};
	--gray-30: #{$gray-300};
	--gray-40: #{$gray-400};
	--gray-50: #{$gray-500};
	--gray-60: #{$gray-600};
	--gray-70: #{$gray-700};
	--gray-80: #{$gray-800};
	--gray-90: #{$gray-900};

	--always-white: white;
	--always-black: black;

	--light: var(--gray-10);
	--dark: var(--gray-80);
	--black: #000;

	--danger: var(--red);
	--warning: var(--yellow);
	--info: var(--cyan);
	--success: var(--green);

	--base-bg: var(--always-white);
	--base-color: var(--gray-80);
	--base-hover-bg: var(--gray-20);
	--base-hover-color: var(--gray-90);

	--base-box-shadow: #{$box-shadow};

	--base-border-radius: #{$border-radius-sm};

	--backdrop-bg: #{$modal-backdrop-bg};
	--backdrop-opacity: #{$modal-backdrop-opacity};

	--box-shadow-sm: #{$box-shadow-sm};
	--box-shadow: #{$box-shadow};
	--box-shadow-lg: #{$box-shadow-lg};

	--line-height-lg: #{$line-height-lg};
	--line-height-sm: #{$line-height-sm};

	--border-width: #{$border-width};
	--border-color: #{$border-color};

	--border-radius: #{$border-radius};
	--border-radius-lg: #{$border-radius-lg};
	--border-radius-sm: #{$border-radius-sm};

	--margin-bottom: 0.8rem;

	--gradient-mask: linear-gradient(
		to right,
		hsla(0, 0%, 10%, 0.6),
		hsla(0, 0%, 60%, 0.4)
	);

	// Form controls
	--label-margin-bottom: #{$label-margin-bottom};

	--input-padding-y: #{$input-padding-y};
	--input-padding-x: #{$input-padding-x};
	--input-font-family: #{$input-font-family};
	--input-font-size: #{$input-font-size};
	--input-font-weight: #{$input-font-weight};
	--input-line-height: #{$input-line-height};

	--input-padding-y-sm: #{$input-padding-y-sm};
	--input-padding-x-sm: #{$input-padding-x-sm};
	--input-font-size-sm: #{$input-font-size-sm};
	--input-line-height-sm: #{$input-line-height-sm};

	--input-padding-y-lg: #{$input-padding-y-lg};
	--input-padding-x-lg: #{$input-padding-x-lg};
	--input-font-size-lg: #{$input-font-size-lg};
	--input-line-height-lg: #{$input-line-height-lg};

	--input-bg: #{$input-bg};
	--input-disabled-bg: #{$input-disabled-bg};

	--input-color: #{$input-color};
	--input-border-color: #{$input-border-color};
	--input-border-width: #{$input-border-width};
	--input-box-shadow: #{$input-box-shadow}; // inset 0 1px 1px rgba($black, .075);

	--input-border-radius: #{$input-border-radius};
	--input-border-radius-lg: #{$input-border-radius-lg};
	--input-border-radius-sm: #{$input-border-radius-sm};

	--input-focus-bg: #{$input-focus-bg};
	--input-focus-border-color: #{$input-focus-border-color}; // $input-border-color
	--input-focus-color: #{$input-focus-color};
	--input-focus-width: #{$input-focus-width};
	--input-focus-box-shadow: #{$input-focus-box-shadow};

	--input-placeholder-color: #{$input-placeholder-color};
	--input-plaintext-color: #{$input-plaintext-color};

	--input-height-border: #{$input-height-border};

	--input-height-inner: #{$input-height-inner};
	--input-height-inner-half: #{$input-height-inner-half};
	--input-height-inner-quarter: #{$input-height-inner-quarter};

	--input-height: #{$input-height};
	--input-height-sm: #{$input-height-sm};
	--input-height-lg: #{$input-height-lg};

	--input-transition: #{$input-transition};

	--select-box-shadow: #{$custom-select-box-shadow};

	//
	// Transições
	//
	--base-transition-fade: #{$transition-fade};
	--base-transition-all: all 0.3s ease-in-out;

	//
	// Navbar
	//
	--navbar-hover-color: var(--primary);
	--navbar-hover-overlay: rgba(
		var(--always-black),
		var(--base-overlay-opacity)
	);
	--navbar-height: 48px;

	//
	// Sidebar
	//
	--sidebar-width: 250px;
	--sidebar-width-collapse: 80px;
	--sidebar-bg: #323742;
	--sidebar-menu-item: var(--gray-60);
	--sidebar-menu-item-hover: var(--gray-30);
	--sidebar-menu-item-active: var(--always-white);

	//
	// Fonte
	//
	--base-font-size: #{$font-size-base};
	--base-font-family: var(--font-family-sans-serif);

	//
	// z-index
	//
	--dropdown-zindex: #{$zindex-dropdown};
	--sticky-zindex: #{$zindex-sticky};
	--fixed-zindex: #{$zindex-fixed};
	--modal-backdrop-zindex: #{$zindex-modal-backdrop};
	--modal-zindex: #{$zindex-modal};
	--popover-zindex: #{$zindex-popover};
	--tooltip-zindex: #{$zindex-tooltip};
}
