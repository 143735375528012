//
// dropdown.scss
//

.dropdown-toggle:after {
	display: inline-block;
	margin-left: 0.4em;
	vertical-align: 0.1em;
	content: '';
	color: var(--gray-40);
	border-top: 0.35em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}
